import React from "react";
import "./_footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <em>Site réalisé par <a href="https://atsumeru.fr/">Mathieu Genet</a></em>
    </footer>
  );
};

export default Footer;
