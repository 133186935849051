import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/footer";
import Content from "../components/Content/Content";
import { Helmet } from "react-helmet";

const HomePage = () => {
  return (
    <div>
      <div>
        <Helmet>
          <title>Véro-créa - l'Échoppe des Minos</title>
          <meta
            name="description"
            content="Découvrez Véro-créa et l'Échoppe des Minos, votre boutique de créations uniques à Billom. Visitez-nous ou explorez notre collection en ligne."
          />
          <meta
            name="keywords"
            content="Véro-créa, Échoppe des Minos, créations artisanales, Billom, boutique artisanale, Véronique Malveau"
          />
          <link rel="canonical" href="http://lechoppedesminos.fr" />
          <link
            rel="alternate"
            href="http://lechoppedesminos.fr"
            hreflang="fr-fr"
          />
          <link rel="alternate" href="http://vero-crea.fr" hreflang="fr-fr" />
          <meta property="og:title" content="Véro-créa - l'Échoppe des Minos" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="http://lechoppedesminos.fr" />
          <meta property="og:image" content="/chemin-vers-votre-image.jpg" />
          <meta
            property="og:description"
            content="Découvrez Véro-créa et l'Échoppe des Minos, votre boutique de créations uniques à Billom. Visitez-nous ou explorez notre collection en ligne."
          />
          <meta property="og:site_name" content="Véro-créa" />
          <meta property="og:locale" content="fr_FR" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Véro-créa - l'Échoppe des Minos"
          />
          <meta
            name="twitter:description"
            content="Découvrez Véro-créa et l'Échoppe des Minos, votre boutique de créations uniques à Billom. Visitez-nous ou explorez notre collection en ligne."
          />
          <meta
            name="twitter:image"
            content="/chemin-vers-votre-image-twitter.jpg"
          />
          <meta name="geo.region" content="FR" />
          <meta name="geo.placename" content="Billom" />
          <meta name="geo.position" content="45.723546;3.341841" />
          <meta name="ICBM" content="45.723546, 3.341841" />
        </Helmet>
      </div>
      <Header />
      <Content />
      <Footer />
    </div>
  );
};

export default HomePage;
