import React from "react";
import "./_header.scss";
import logoVeroCrea from "../../assets/images/logo_vero-crea.png";
import logoEchoppeDesMinos from "../../assets/images/logo_echoppe_des_minos.png";

const Header = () => {
  return (
    <header className="header">
      <div>
        <img src={logoVeroCrea} className="logo" alt="Logo Vero Crea" />
        <img
          src={logoEchoppeDesMinos}
          className="logo"
          alt="Logo Echoppe Des Minos"
        />
      </div>
      <nav>
        <li className="a-propos">A propos</li>
        <li className="vero-crea"><a href="#VeroCrea">Véro-créa</a></li>
        <li className="echoppe-des-minos"><a href="#EchoppeMinos">L'Echoppe des Minos</a></li>
      </nav>
    </header>
  );
};

export default Header;
