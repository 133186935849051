import React, { useState } from "react";
import "./_content.scss";
import logoVeroCrea from "../../assets/images/portrait.png";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import instagramLogo from "../../assets/images/Instagram_icon.png";
import facebookLogo from "../../assets/images/Facebook_logo_(square).png";
import veroCrea01 from "../../assets/images/_1.jpg";
import veroCrea02 from "../../assets/images/_2.jpg";
import veroCrea03 from "../../assets/images/_3.jpg";
import { FiArrowRight, FiArrowLeft, FiX } from "react-icons/fi";

const Content = () => {
  const images = [
    veroCrea01,
    veroCrea02,
    veroCrea03,
    veroCrea01,
    veroCrea02,
    veroCrea03,
  ];

  const [lightboxDisplay, setLightboxDisplay] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setLightboxDisplay(true);
  };

  const goToNextImage = (event) => {
    event.stopPropagation(); 
    setCurrentImage((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPreviousImage = (event) => {
    event.stopPropagation(); 
    setCurrentImage((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const closeLightbox = (event) => {
    event.stopPropagation();
    setLightboxDisplay(false);
  };

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const containerStyle = {
    width: "400px",
    height: "400px",
  };

  const center = {
    lat: 45.72354598557861,
    lng: 3.3418414245685937,
  };

  const weekDays = [
    "dimanche",
    "lundi",
    "mardi",
    "mercredi",
    "jeudi",
    "vendredi",
    "samedi",
  ];
  const openingHours = {
    jeudi: ["09:30-12:30", "14:30-18:00"],
    vendredi: ["09:30-12:30", "14:30-18:00"],
    samedi: ["09:00-12:00"],
    dimanche: ["Fermé"],
    lundi: ["09:30-12:00", "14:00-17:00"],
    mardi: ["Fermé"],
    mercredi: ["09:00-12:00", "14:30-18:00"],
  };

  const currentDay = new Date().getDay();
  const currentTime = new Date().toTimeString().slice(0, 5);

  const isOpenNow = () => {
    const todayHours = openingHours[weekDays[currentDay]];
    if (todayHours.includes("Fermé")) {
      return false;
    }

    return todayHours.some((timeRange) => {
      const [open, close] = timeRange.split("–");
      return currentTime >= open && currentTime <= close;
    });
  };

  const renderOpeningHours = () => {
    return weekDays.map((day, index) => {
      const isToday = index === currentDay;
      const dayHours = openingHours[day];

      return (
        <li key={day} style={{ fontWeight: isToday ? "bold" : "normal" }}>
          {day.charAt(0).toUpperCase() + day.slice(1)} : {dayHours.join(", ")}
        </li>
      );
    });
  };

  return (
    <main className="content">
      <div class="presentation-container">
        <img
          src={logoVeroCrea}
          className="client-photo"
          alt="Portrait de la créatrice"
        />
        <div class="text-block">
          <p>
            Bienvenue sur mon espace dédié à la passion qui anime mon quotidien
            : la créativité au service des tout-petits et l'engagement en faveur
            d'une mode enfantine plus responsable.
          </p>

          <p>
            À travers Véro-Créa et L'échoppe des Minos, je souhaite partager
            avec vous une vision : celle d'un monde où la consommation est
            réfléchie, la qualité privilégiée sur la quantité, et la beauté des
            choses simples célébrée. Joignez-vous à moi dans cette aventure
            pleine de sens, pour offrir à nos enfants un futur rempli de
            possibilités, de créativité, et de respect de l'environnement.
          </p>
        </div>
      </div>
      <div className="content-choice">
        <div className={`vero-crea-block`} id="VeroCrea">
          <div className="base-content">
            <h2>Véro Créa</h2>
            <p>
              Véro-Créa est le fruit de mon amour pour l'artisanat et la douceur
              de l'enfance. Chaque création, faite main avec amour en feutrine
              ou textiles sélectionnés pour leur qualité, est conçue pour
              apporter joie et couleur dans la vie de vos enfants. Des cadeaux
              de naissance uniques, des objets de décoration pour la chambre de
              bébé, chaque pièce est créée avec l'idée de toucher le cœur des
              petits comme des grands.
            </p>
          </div>
          <div className="gallery">
            <div className="gallery-container">
              {images.map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt={`Gallery item ${index}`}
                  onClick={() => openLightbox(index)}
                  className="gallery-item"
                />
              ))}
            </div>

            {lightboxDisplay && (
              <div className="lightbox" onClick={closeLightbox}>
                <button
                  className="lightbox-close"
                  onClick={(e) => closeLightbox(e)}
                >
                  <FiX />
                </button>
                <button
                  className="lightbox-prev"
                  onClick={(e) => goToPreviousImage(e)}
                >
                  <FiArrowLeft />
                </button>
                <img
                  className="lightbox-image"
                  src={images[currentImage]}
                  alt="Enlarged pic"
                />
                <button
                  className="lightbox-next"
                  onClick={(e) => goToNextImage(e)}
                >
                  <FiArrowRight />
                </button>
              </div>
            )}
          </div>
          <div className="reseaux-content">
            <div>
              <a href="https://www.facebook.com/verocreafeutrine">
                <img
                  src={facebookLogo}
                  className="facebook-logo"
                  alt="Logo de facebook"
                />
              </a>
            </div>
            <div>
              <a href="https://www.instagram.com/vero.crea63/">
                <img
                  src={instagramLogo}
                  className="instagram-logo"
                  alt="Logo de instagram"
                />
              </a>
            </div>
          </div>
        </div>
        <div className={`echoppe-des-minos-block`}  id="EchoppeMinos">
          <div className="base-content">
            <h2>Échoppe des Minos</h2>
            <p>
              L'échoppe des Minos représente mon engagement envers un mode de
              vie plus éco-responsable. Dans cet espace, vous découvrirez une
              sélection méticuleuse de vêtements seconde-main pour enfants de 0
              à 8 ans. Chaque article est choisi avec le plus grand soin, pour
              vous offrir la possibilité d'habiller vos enfants avec des pièces
              de qualité à petit prix, tout en faisant un geste significatif
              pour notre planète.
            </p>
          </div>
          <div className="map-content">
            <div>
              <LoadScript
                googleMapsApiKey={apiKey}
                libraries={["maps", "marker"]}
              >
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={14}
                  mapId="DEMO_MAP_ID"
                >
                  <Marker position={center} title="L'Echoppe des Minos" />
                </GoogleMap>
              </LoadScript>
            </div>
            <div>
              <p>
                {isOpenNow()
                  ? "Nous sommes ouverts maintenant"
                  : "Nous sommes fermés maintenant"}
              </p>
              <ul>{renderOpeningHours()}</ul>
              <p>
                Pour nous contacter, appelez-nous au :{" "}
                <a href="tel:+33463086849">04 63 08 68 49</a>
              </p>
            </div>
          </div>
          <div className="reseaux-content">
            <div>
              <a href="https://www.facebook.com/lechoppedesminos">
                <img
                  src={facebookLogo}
                  className="facebook-logo"
                  alt="Logo de facebook"
                />
              </a>
            </div>
            <div>
              <a href="https://www.instagram.com/lechoppedesminos/">
                <img
                  src={instagramLogo}
                  className="instagram-logo"
                  alt="Logo de instagram"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Content;
